.App {
  background-color: #282c34;
  min-height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  padding: 20px;
  color: white;
  text-align: center;
}

.App-header h1 {
  margin: 0;
  font-size: 2rem;
}

.App-main {
  min-height: calc(100vh - 300px);
  padding: 20px;
  margin: 0 auto;
  display: flex;
  gap: 20px;
  overflow: hidden;
}

.editor-container {
  flex: 1;
  margin-bottom: 0;
  padding: 10px;
  background-color: #2c313c;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  min-width: 0;
  overflow: hidden;
}

.editor-container:first-child {
  flex: 1;
}

.editor-container:last-child {
  flex: 1;
}

.editor-container h2 {
  color: white;
  text-align: left;
  margin-bottom: 10px;
}

.format-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin-top: 32px;
  flex: 0 0 60px;
}

.format-button {
  width: 40px;
  height: 40px;
  padding: 8px;
  background-color: #61dafb;
  border: none;
  border-radius: 50%;
  color: #282c34;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.format-button:hover {
  background-color: #4fa8d3;
  transform: scale(1.1);
}

.format-button svg {
  width: 24px;
  height: 24px;
}

/* CodeMirror 自定义样式 */
.cm-editor {
  border-radius: 4px;
  height: calc(100vh - 400px) !important;
  border: 1px solid #3f4555;
  background-color: #1e2127 !important;
  overflow: auto !important;
}

.cm-editor:hover {
  border-color: #4fa8d3;
}

.cm-editor .cm-scroller {
  background-color: #1e2127 !important;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.cm-editor .cm-content {
  text-align: left !important;
}

.App-footer {
  padding: 20px;
  background-color: #1e2127;
  margin-top: 20px;
}

.footer-links {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
  display: flex;
}

.footer-links h3 {
  color: white;
  margin: 0;
}

.footer-links a {
  color: #61dafb;
  text-decoration: none;
  margin: 0 10px;
  transition: color 0.3s;
}

.footer-links a:hover {
  color: #4fa8d3;
  text-decoration: underline;
}

/* 添加支持的格式标签样式 */
.supported-formats {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  margin-top: 10px;
}

.format-badge {
  background-color: #61dafb;
  color: #282c34;
  padding: 4px 12px;
  border-radius: 12px;
  font-size: 0.9rem;
  cursor: help;
}

.format-badge:hover {
  background-color: #4fa8d3;
}

.cm-editor .cm-line {
  white-space: pre !important;
  word-break: normal !important;
}

.cm-editor::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.cm-editor::-webkit-scrollbar-track {
  background: #1e2127;
}

.cm-editor::-webkit-scrollbar-thumb {
  background: #4a4f5c;
  border-radius: 4px;
}

.cm-editor::-webkit-scrollbar-thumb:hover {
  background: #5a6070;
}

/* 添加结果区域标题栏样式 */
.result-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.result-header h2 {
  margin: 0;
}

.copy-button {
  background-color: #61dafb;
  color: #282c34;
  border: none;
  border-radius: 4px;
  padding: 6px 12px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.copy-button:hover {
  background-color: #4fa8d3;
  transform: scale(1.05);
}

/* Default: hide on larger screens */
.mobile-buttons {
  display: none;
}

/* 在小屏幕上调整布局 */
@media (max-width: 768px) {
  .App-main {
    flex-direction: column;
    gap: 10px;
  }

  .editor-container {
    width: 100%;
    max-width: none;
  }

  .format-button-container {
    flex: 0 0 40px;
    margin: 10px 0;
  }

  .App-main {
    padding: 10px;
  }

  .editor-container {
    width: 98%;
    margin: 10px 0;
  }

  .format-button-container {
    margin: 10px 0;
  }

  /* 在移动端隐藏原来的格式化按钮 */
  .format-button-container {
    display: none;
  }

  /* 移动端的按钮样式 */
  .mobile-buttons {
    display: flex;
    gap: 10px;
    margin-top: 10px;
  }

  .mobile-buttons button {
    flex: 1;
    padding: 10px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
  }

  .clear-button {
    background-color: #666;
    color: white;
  }

  .format-button {
    background-color: #4CAF50;
    color: white;
  }
}

/* 格式徽章的样式更新 */
.format-badge {
  position: relative;
  padding: 4px 8px;
  background-color: #977e7e;
  border-radius: 4px;
  margin: 4px;
  cursor: pointer;
  user-select: none;
  transition: background-color 0.2s;
}

.format-badge:active {
  background-color: #555;
}

.format-tooltip {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: white;
  padding: 8px;
  border-radius: 4px;
  font-size: 14px;
  white-space: nowrap;
  z-index: 1000;
  margin-bottom: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

/* 添加小三角形 */
.format-tooltip::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 6px;
  border-style: solid;
  border-color: #333 transparent transparent transparent;
}

/* 优化复制按钮在移动端的显示 */
.copy-button {
  padding: 6px 12px;
  font-size: 14px;
}

/* 确保文本不会溢出屏幕 */
.editor-container {
  max-width: 100%;
  overflow-x: auto;
}

/* 优化 CodeMirror 在移动端的显示 */
.cm-editor {
  font-size: 14px;
  max-width: 100%;
  overflow-x: auto;
}

/* 适配深色模式 */
@media (prefers-color-scheme: dark) {
  .format-tooltip {
    background-color: #444;
  }

  .format-tooltip::after {
    border-color: #444 transparent transparent transparent;
  }
}